<template>
  <div>
    <vs-input icon-after="true" :v-validate="vValidate" :data-vv-as="dataVvAs" :name="name" icon-pack="feather" icon="icon-search" readonly :label="label" class="w-full" v-model="nameBrowse" @click="browse"></vs-input>
    <input type="text" hidden :value="value">

    <vs-popup title="Find Price List" fullscreen :active.sync="isPopUp">
      <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
        <template slot="thead">
          <vs-th sort-key="name">Name</vs-th>
          <vs-th sort-key="route">Route</vs-th>
          <vs-th sort-key="mode">Mode</vs-th>
          <vs-th sort-key="commodity">Commodity</vs-th>
          <vs-th sort-key="commodity_type">Commodity Type</vs-th>
          <vs-th sort-key="vehicle_type">Vehicles</vs-th>
          <vs-th sort-key="price_tonase">Price per Kg</vs-th>
          <vs-th sort-key="price_volume">Price per m<sup>3</sup></vs-th>
          <vs-th sort-key="price_item">Price per Item</vs-th>
          <vs-th sort-key="price_full">Full Price</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name">
              <a class="pointer" @click="changeValue(tr.id)">{{tr.name}}</a>
            </vs-td>
            <vs-td label="Route" :data="tr.route">{{tr.route}}</vs-td>
            <vs-td label="Mode" :data="tr.mode">{{tr.mode}}</vs-td>
            <vs-td label="Commodity" :data="tr.commodity">{{tr.commodity}}</vs-td>
            <vs-td label="Commodity Type" :data="tr.commodity_type">{{tr.commodity_type}}</vs-td>
            <vs-td label="Vehicles" :data="tr.vehicle_type">{{tr.vehicle_type}}</vs-td>
            <vs-td label="Price per Kg" class='text-right' :data="tr.price_tonase">{{tr.price_tonase|numFormat}}</vs-td>
            <vs-td label="Price per m<sup>3</sup>" class='text-right' :data="tr.price_volume">{{tr.price_volume|numFormat}}</vs-td>
            <vs-td label="Price per Item" class='text-right' :data="tr.price_item">{{tr.price_item|numFormat}}</vs-td>
            <vs-td label="Full Price" class='text-right' :data="tr.price_full">{{tr.price_full|numFormat}}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
    </vs-popup>
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
  props: ['value','label','vValidate','dataVvAs','name','params', 'customer_id'],
  data(){
    return {
      nameBrowse: "",
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      isPopUp:false
    }
  },
  watch:{
    async value(val) {
      if (val) {
        this.changeValue(val)
      } else {
        this.nameBrowse = ""
      }
    },
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.price_lists.rows
    })
  },
  mounted() {
    if(this.value) {
        this.changeValue(this.value)
    }
  },
  methods:{
    async browse(){
      await this.getData()
      this.isPopUp=true
    },
    async changeValue(value){
      let {data} = await this.dispatchShow(value)
      this.$emit('input', data.id)
      this.$emit('on-change', data)
      this.nameBrowse = `${data.name}`
      this.isPopUp=false
    },
    ...mapActions({
      dispatchIndex: 'price_lists/index',
      dispatchShow: 'price_lists/show'
    }),
    async getData(){
      let payload = {
        customer_id: this.customer_id,
        contract_id: this.contract_id,
        is_active: true,
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal,
        ...this.params
      }
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  }
}
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>
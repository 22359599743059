<template>
  <div>
      <small class="ml-2">Car Factory</small>
      <v-select v-model="car_factory_id" :options="car_factories" :name="nameBrowse" label='name' :reduce="e => e.id" @input='changeValue()'></v-select>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import vSelect from 'vue-select'

export default {
  props: ['value','label','vValidate','dataVvAs','name','params'],
  components:{
    vSelect
  },
  data(){
    return {
      nameBrowse: "",
      car_factory_id : null,
      currentPage: 1,
      car_factories : [],
      search:null,
      sortKey:null,
      sortVal:null,
      isPopUp:false
    }
  },
  watch:{
    async value(val) {
      if (val) {
        this.changeValue(val)
      } else {
        this.nameBrowse = ""
      }
    },
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  async mounted() {
    this.getData()
    if(this.value) {
        this.car_factory_id = this.value
        this.changeValue()
    }
  },
  methods:{
    async changeValue(){
      let data = this.car_factories.find(x => x.id == this.car_factory_id)
      this.$emit('input', data.id)
      this.$emit('on-change', data)
      this.nameBrowse = `${data.name}`
      this.isPopUp=false
    },
    ...mapActions({
      dispatchIndex: 'car_factories/all'
    }),
    async getData(){
      let {data} = await this.dispatchIndex()
      this.car_factories = data
      // console.log(this.car_factories)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  }
}
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>